import React, { useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from '@mui/material/Checkbox';
import InputLabel from "@material-ui/core/InputLabel";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Button } from '@material-ui/core';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { AddOutlined } from '@mui/icons-material';
import initArrFile from './initArr'
import ReactGA from 'react-ga';


ReactGA.initialize('G-BM2ZDYB70J');
ReactGA.pageview(window.location.pathname + window.location.search);
// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBsWPYB__d3aBoAAhL9d2Rl0n0cKbXFI3s",
  authDomain: "tiptop-4ba3c.firebaseapp.com",
  projectId: "tiptop-4ba3c",
  storageBucket: "tiptop-4ba3c.appspot.com",
  messagingSenderId: "804535636021",
  appId: "1:804535636021:web:cb3f76622a2e4baa66217c",
  measurementId: "G-L1LQ0PG2R8"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    margin: 'auto',
    
  },
  indeterminateColor: {
    color: "#f50057"
  },
  selectAllText: {
    fontWeight: 500
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)"
    }
  }
}));

let initArr = initArrFile;
// YYYY-MM-DDTHH:MM:SSZ
const updatedate = new Date('2022-05-04T17:01:00Z');
const updateDateIsrael = changeTimezone(updatedate, 'Asia/Jerusalem')

const rateOfAddingMinutes = 10;
const amountAdded = 350;


const currentDate = new Date();
const currentDateInIsrael = changeTimezone(currentDate, 'Asia/Jerusalem')

function changeTimezone(date, ianatz) {

  // suppose the date is 12:00 UTC
  var invdate = new Date(date.toLocaleString('en-US', {
    timeZone: ianatz
  }));

  // then invdate will be 07:00 in Toronto
  // and the diff is 5 hours
  var diff = date.getTime() - invdate.getTime();

  // so 12:00 in Toronto is 17:00 UTC
  return new Date(date.getTime() - diff); // needs to substract

}

let minutesPassedFromUpdate = getDifferenceInMinutes(updateDateIsrael, currentDateInIsrael)

function getDifferenceInMinutes(date1, date2) {
  const diffInMs = Math.abs(date2 - date1);
  return diffInMs / (1000 * 60);
}

let neededToAddArr = initArr.splice(initArr.length - amountAdded, amountAdded);

for(let asqw = 0; asqw < (minutesPassedFromUpdate / rateOfAddingMinutes); asqw++){
  initArr.push(neededToAddArr[asqw]);
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center"
  },
  variant: "menu"
};

let options = [];

function shuffle(array) {
  let currentIndex = array.length,  randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {

    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}

shuffle(initArr);

for(let x = 0; x < initArr.length; x++){
  let tags = [];
  if(initArr[x] && initArr[x].Categories){
  if(initArr[x].Categories.includes(",")){
    tags = initArr[x].Categories.split(',');
  } else {
    tags = [initArr[x].Categories];
  }
  for(let z = 0; z < tags.length; z++){
    if(tags[z][0] === ' '){
      tags[z] = tags[z].slice(1);
    }
    if(!options.includes(tags[z])){
      options.push(tags[z]);
    }
  }
}
}

const filterBytags = ( list, filters ) => {
  let newArr = [];
  for(let i = 0; i < list.length; i++){
    if(list[i] && list[i].Categories){
    for(let j = 0; j < list[i].Categories.length; j++){
      for(let k = 0; k < filters.length; k++){
        if(filters[k].toLowerCase() === list[i].Categories[j].toLowerCase()){
          newArr.push(list[i]);
          j = list[i].Categories.length;
          k = filters.length;
        }
      } 
    }
  }
  }
  return newArr; 
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

let newArr = [];
let asdf = 0;
while(initArr.length > 0 && asdf < 10000){
  for(let a = 0; a < options.length; a++){
    let isFound = false;
    for(let j = 0; j < initArr.length; j++){
      if(initArr[j] && initArr[j].Categories){
        if(initArr[j].Categories.includes(",")){
          initArr[j].Categories = initArr[j].Categories.split(',');
          initArr[j].Categories.forEach((element, ind) => {
          if(element[0] === ' '){
            initArr[j].Categories[ind] = initArr[j].Categories[ind].slice(1);
          }
        });
        } else if(typeof initArr[j].Categories === 'string') {
          initArr[j].Categories = [initArr[j].Categories];
        }
      }
    if(initArr[j] && initArr[j].Categories && initArr[j].Categories.includes(options[a]) && isFound === false){
      initArr.splice(j, 1);
      newArr.push(initArr[j]);
        isFound = true;
      }
}
}
asdf++;
}

initArr = newArr;

function App() {
  let selectedInit = [];
  let retrievedData = localStorage.getItem("categories");
  let categories = JSON.parse(retrievedData);
  if(categories != null && categories.length > 0){
    selectedInit = categories;
  } else {
    selectedInit = options;
  }

  const perPage = 5;
  const [filteredArr, setFilteredArr] = useState(filterBytags(initArr, selectedInit));
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(filteredArr.length / (perPage - 1))
  const [shownVideos, setshownVideos] = useState([]);
  const [loading, setLoading] = useState(false);
  const { height, width } = useWindowDimensions();
  const classes = useStyles();
  const [selected, setSelected] = useState(selectedInit);
  const isAllSelected =
    options.length > 0 && selected.length === options.length;

  const handleChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelected(selected.length === options.length ? [] : options);
      localStorage.setItem("categories", JSON.stringify(selected.length === options.length ? [] : options));

      let filtered = filterBytags(initArr, selected.length === options.length ? [] : options);
      setFilteredArr(filtered);
      setTotalPages(filtered.length / (perPage - 1));
      setshownVideos(paginate(filtered, perPage, 1));
      return;
    }
    setSelected(value);
    localStorage.setItem("categories", JSON.stringify(value));
    let filtered = filterBytags(initArr, value);
    setFilteredArr(filtered);
    setTotalPages(filtered.length / (perPage - 1));
    setshownVideos(paginate(filtered, perPage, 1));
  };

  function paginate(array, page_size, page_number) {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.slice(0, page_number * page_size);
  }

  useEffect(() => {
    const getshownVideos = () => {
      setLoading(true);
      setshownVideos(paginate(filteredArr, perPage, page));
      setLoading(false);
    }
    getshownVideos();
  }, [page]);


  return (
    <div style={{margin: 0}}>
 <Box sx={{ flexGrow: 1 }}>
      <AppBar style={{background: '#ffffff'}} position="static">
        <Toolbar>
          
          <Typography color="#000000" variant="h6" component="div" sx={{ flexGrow: 1 }}>
            TipTop
            <div style={{height: 10, fontSize: 10, position: 'relative', top: -18}} ><br/>Scrolling that makes you smarter </div>
          </Typography>
          <Button target="_blank" href="https://forms.gle/1uEjB9dzvst493vN8" color="#000000"><AddOutlined/></Button>
        </Toolbar>
      </AppBar>
    </Box>

      <div style={{padding: 5, maxWidth: 540, width: 'calc(100% - 10px)', display: 'block', margin: 'auto', marginBottom: 15, marginTop: 5}}>
        
        <FormControl className={classes.formControl}>
      <InputLabel id="mutiple-select-label">Topics I wish to learn</InputLabel>
      <Select
        labelId="mutiple-select-label"
        multiple
        value={selected}
        onChange={handleChange}
        renderValue={(selected) => selected.join(", ")}
        MenuProps={MenuProps}
      >
        <MenuItem
          value="all"
          classes={{
            root: isAllSelected ? classes.selectedAll : ""
          }}
        > 
          <ListItemIcon>
            <Checkbox
              classes={{ indeterminate: classes.indeterminateColor }}
              checked={isAllSelected}
              indeterminate={
                selected.length > 0 && selected.length < options.length
              }
            />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.selectAllText }}
            primary="Select All"
          />
        </MenuItem>
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            <ListItemIcon>
              <Checkbox checked={selected.indexOf(option) > -1} />
            </ListItemIcon>
            <ListItemText primary={option} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
    </div>
    <div style={{maxWidth: 520, display: 'block', margin: 'auto' }}>
    {shownVideos.map((x, i) => {
        return <div style={{ margin: 0}} key={x.TikTokLink + i}>
                <iframe frameBorder="0" style={{ height: height > 650 ? 650 : height, width: width > 520 ? 520 : width, margin: 0}} src={"https://www.tiktok.com/embed/v2/" + x.TikTokLink.split('/video/')[1].split('?')[0] + "?autoplay=1"}/>
                <div style={{marginBottom: 25, marginTop: 5}}>
                <div style={{width: '100%', maxWidth: 323, display: 'block', margin: 'auto'}}>
                {x.Categories.map((tag, i) => {
                  return <Chip key={tag+i} style={{marginRight: 5, marginBottom: 5}} variant="outlined" label={tag}/>})}
                  </div>
                  </div>
        </div>
      })}
      </div>
      <div className="clearfix"></div>
      {filteredArr.length >  shownVideos.length ? <Button style={{display: 'block',margin: 'auto', marginBottom: 20}} variant='contained' onClick={() => setPage(page + 1)}>{loading ? 'Loading...' : 'Load More'}</Button> : <Typography style={{textAlign: 'center', display:'block', margin: 'auto', marginBottom: 20}}>No more videos for chosen categories</Typography>}
    </div>
  );
}

export default App;
